@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
* {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	box-sizing: border-box;
	list-style: none;
	text-decoration: none;
}
:root {
	/* --color-bg: #1f1f38;
	--color-bg-variant: #2c2c6c;
	--color-primary: #4db5ff;
	--color-primary-variant: rgba(77, 181, 255, 0.4);
	--color-white: #fff;
	--color-light: rgba(255, 255, 255, 0.6); */

	--container-width-lg: 75%;
	--container-width-md: 86%;
	--container-width-sm: 90%;

	--transition: all 400ms ease;
}
[data-theme='blue-theme'] {
	--color-bg: #1f1f38;
	--color-bg-variant: #2c2c6c;
	--color-primary: #4db5ff;
	--color-primary-variant: rgba(77, 181, 255, 0.4);
	--color-white: #fff;
	--color-light: rgba(255, 255, 255, 0.6);
}

[data-theme='grey-theme'] {
	--color-bg: #94b49f;
	--color-bg-variant: #b4cfb0;
	--color-primary: #5c7173;
	--color-primary-variant: rgba(92, 113, 115, 0.4);
	--color-white: #fff;
	--color-light: rgba(255, 255, 255, 0.6);
}
[data-theme='black-theme'] {
	--color-bg: #191919;
	--color-bg-variant: #555555;
	--color-primary: #a0aecd;
	--color-primary-variant: rgba(160, 174, 205, 0.4);
	--color-white: #fff;
	--color-light: rgba(255, 255, 255, 0.6);
}
html {
	scroll-behavior: smooth;
}
::-webkit-scrollbar {
	display: none;
}
body {
	font-family: 'Poppins', sans-serif;
	background: var(--color-bg);
	color: var(--color-white);
	line-height: 1.7;
}

.App {
	background: var(--color-bg);
	color: var(--color-white);
	width: 100%;
	min-height: 100vh;
}
/* ==================== Gerneral Style ====================== */

.container {
	width: var(--container-width-lg);
	margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 500;
}

h1 {
	font-size: 2.5rem;
}

section {
	margin-top: 8rem;
}

section > h2,
section > h5 {
	text-align: center;
	color: var(--color-light);
}

section > h2 {
	color: var(--color-primary);
	margin-bottom: 3rem;
}

.text-light {
	color: var(--color-light);
}

a {
	color: var(--color-primary);
	transition: var(--transition);
}

a:hover {
	color: var(--color-white);
}

.btn {
	width: max-content;
	display: inline-block;
	color: var(--color-primary);
	padding: 0.7rem 1.2rem;
	border-radius: 0.4rem;
	cursor: pointer;
	border: 1px solid var(--color-primary);
	transition: var(--transition);
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.btn:hover {
	background: var(--color-white);
	color: var(--color-bg);
	border-color: transparent;
}

.btn-primary {
	background-color: var(--color-primary);
	color: var(--color-bg);
}

img {
	display: block;
	width: 100%;
	object-fit: cover;
}

/* =========================== Tablets (Medium Devices) =================== */
@media screen and (max-width: 1024px) {
	.container {
		width: var(--container-width-md);
	}
	section {
		margin-top: 6rem;
	}
}

/* =========================== Mobile (Small Devices) =================== */
@media screen and (max-width: 600px) {
	.container {
		width: var(--container-width-sm);
	}
	section > h2 {
		margin-bottom: 2rem;
	}
}
