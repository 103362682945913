.themes {
	width: 200px;
	height: 50px;
	background: white;
	position: fixed;
	right: -150px;
	top: 100px;
	border-radius: 4rem 0 0 4rem;
	display: flex;
	align-items: center;
	color: #424242;
	font-size: 1.8rem;
	transition: all ease 400ms;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	z-index: 99;
}

.setting__icon {
	/* background: green; */
	width: 50px;
	height: 50px;
	align-items: center;
	display: flex;
	justify-content: center;
	text-align: center;
}

.themes:hover {
	right: 0px;
}

.theme-options {
	width: 150px;
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.theme__option {
	cursor: pointer;
	width: 30px;
	height: 30px;
	border-radius: 4px;
	margin: 2px 10px;
	border: 1px solid white;
	display: inline-block;
}

.icon {
	animation: rotate 5s infinite linear;
	font-size: 1.6rem;
}

.bg__color {
	height: 15px;
	border-radius: 4px 4px 0 0;
}
.primary__color {
	height: 15px;
	border-radius: 0 0 4px 4px;
}

.theme-blue-bg {
	background: #1f1f38;
}
.theme-blue-primary {
	background: #4db5ff;
}

.theme-grey-bg {
	background: #94b49f;
}
.theme-grey-primary {
	background: #5c7173;
}

.theme-black-bg {
	background: #191919;
}
.theme-black-primary {
	background: #a0aecd;
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
