footer {
	background: var(--color-primary);
	text-align: center;
	font-size: 0.9rem;
	margin-top: 7rem;
	padding: 3rem 0;
}

footer a {
	color: var(--color-bg);
}

.footer__logo {
	font-size: 2rem;
	font-weight: 500;
	margin-bottom: 3rem;
	display: inline-block;
}

.footer__permalinks {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 2rem;
	margin: 0 auto 3rem;
}

.footer__socials {
	display: flex;
	justify-content: center;
	gap: 1rem;
	margin-bottom: 4rem;
}

.footer__socials a {
	background: var(--color-bg);
	color: var(--color-white);
	padding: 0.8rem;
	border-radius: 0.7rem;
	display: flex;
	border: 1px solid transparent;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
		rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
		rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.footer__socials a:hover {
	background: transparent;
	color: var(--color-bg);
	border-color: var(--color-bg);
}

.footer__copyright {
	margin-bottom: 4rem;
	color: var(--color-bg);
}

/* =========================== Mobile (Small Devices) =================== */
@media screen and (max-width: 600px) {
	.footer__permalinks {
		flex-direction: column;
		gap: 1.5rem;
	}
	.footer__socials {
		margin-bottom: 2.6rem;
	}
}
