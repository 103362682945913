/* ----------------- Page Loader starts ------------- */
.page-loader {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 999;
	background-color: var(--white-alpha-25);
	backdrop-filter: var(--backdrop-filter-blur);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 0.6s ease;
}
.page-loader.fadeOut {
	opacity: 0;
}
.page-loader div {
	border: 2px solid transparent;
	position: absolute;
	border-radius: 50%;
	opacity: 0.6;
	border-top-color: transparent !important;
	border-bottom-color: transparent !important;
	animation: spin 1s linear infinite;
}
.page-loader div:nth-child(1) {
	height: 60px;
	width: 60px;
	border-color: var(--color-bg-variant);
}
.page-loader div:nth-child(2) {
	height: 45px;
	width: 45px;
	border-color: var(--color-primary);
	animation-duration: 1.2s;
}
.page-loader div:nth-child(3) {
	height: 30px;
	width: 30px;
	border-color: var(--color-primary-variant);
	animation-duration: 1.4s;
}
.page-loader div:nth-child(4) {
	height: 15px;
	width: 15px;
	border-color: var(--color-white);
	animation-duration: 1.4s;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/* ----------------- Page Loader ends ------------- */
